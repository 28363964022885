<template>
    <common-form
            ref="form"
            url="web/beian/record"
            :edit="edit"
            :items="items"
            @success="$emit('success')">

    </common-form>
</template>
<script>

    export default {
        data() {
            return {
                edit: false,
                productList:[]
            }
        },
        computed: {
            productOptions(){
                let arr = []
                this.productList.forEach(item=>{
                    arr.push({
                        name:item.name,
                        value:item.id
                    })
                })
                return arr
            },
            items() {
                return [
                    {
                        type: "select",
                        name: "客户类型",
                        key: "customType",
                        required: true,
                        options:[
                            { name:"备案客户",value:0 },
                            { name:"绑定客户",value:1 },
                        ]
                    },
                    {
                        type: "select",
                        name: "购买意向",
                        key: "productId",
                        required: true,
                        options:this.productOptions
                    },
                    {
                        type: "input",
                        name: "购买价额",
                        key: "productPrice",
                        required: true,
                        suffix: "元"
                    },

                    {
                        type: "input",
                        name: "介绍人",
                        key: "name",
                        required: true,
                    },
                    {
                        type: "input",
                        name: "介绍人电话",
                        key: "phone",
                        required: true,
                    },
                    {
                        type: "select",
                        name: "能否协助安装",
                        key: "canHelp",
                        required: true,
                        options:[
                            { name:"可以协助",value:true },
                            { name:"无法提供协助",value:false },
                        ]
                    },

                    {
                        type: "input",
                        name: "意向客户",
                        key: "targetName",
                        required: true,
                    },
                    {
                        type: "input",
                        name: "意向客户电话",
                        key: "targetPhone",
                        required: true,
                    },
                    {
                        type: "input",
                        name: "客户地址",
                        key: "targetAddress",
                        required: true,
                    },
                ]
            }
        },
        methods: {
            show(model,productList) {
                this.productList=productList
                let data = JSON.parse(JSON.stringify(model))
                this.edit = model.id > 0
                this.$refs.form.show(data)
            },

        }
    }
</script>
