<template>
    <page-content>

        <common-table
                ref="table"
                path="web/beian/record"
                :queryParams="queryParams"
                :columns="columns">

            <template slot="search">

                <!--        <a-button  ghost type="primary" @click="add">新增</a-button>-->
                <a-radio-group v-model="queryParams.status" @change="$refs.table.handleReset()">
                    <a-radio-button :value="null">全部</a-radio-button>
                    <a-radio-button :value="0">待处理</a-radio-button>
                    <a-radio-button :value="1">已同意</a-radio-button>
                    <a-radio-button :value="2">已拒绝</a-radio-button>
                </a-radio-group>

                <a-button @click="clickExport"><a-icon type="export" />导出Excel</a-button>
            </template>

            <template slot="logo" slot-scope="{record}">
                <a-avatar shape="square" :size="60" :src="record.logo">{{record.title}}</a-avatar>
            </template>

            <template slot="status" slot-scope="{record}">

                <a-dropdown :trigger="['click']" v-if="record.status==0">
                    <a-menu slot="overlay">
                        <a-menu-item key="1" @click="handleSetStatus(record,1)">同意</a-menu-item>
                        <a-menu-item key="2" @click="handleSetStatus(record,2)">拒绝</a-menu-item>
                    </a-menu>
                    <a-button type="danger" size="small">待处理
                        <a-icon type="down"></a-icon>
                    </a-button>
                </a-dropdown>

                <a-tag v-else-if="record.status==1" color="green">已同意</a-tag>
                <a-tag v-else color="red">已拒绝</a-tag>
            </template>

            <template slot="deal" slot-scope="{record}">

<!--                绑定客户，无须成交-->
                <div v-if="record.customType==1">无需成交</div>

                <a-tag v-else-if="record.deal" color="green">已成交</a-tag>

                <a-dropdown :trigger="['click']" v-else>
                    <a-menu slot="overlay">
                        <a-menu-item key="1" @click="handleSetDeal(record)">已成交</a-menu-item>
                    </a-menu>
                    <a-button type="danger" size="small">未成交
                        <a-icon type="down"></a-icon>
                    </a-button>
                </a-dropdown>

            </template>

            <template slot="canHelp" slot-scope="{record}">
                <a-tag v-if="record.canHelp" color="green">可以协助</a-tag>
                <a-tag v-else color="cyan">无法协助</a-tag>
            </template>

            <template slot="operation" slot-scope="{record}">
                <action-message @click="viewComment(record)"></action-message>
                <action-edit @click="edit(record)"></action-edit>
                <action-delete @click="remove(record)"></action-delete>
            </template>

            <template slot="operation" slot-scope="{record}">
                <action-edit @click="edit(record)"></action-edit>
                <action-delete @click="remove(record)"></action-delete>
            </template>

        </common-table>

        <BeianEdit ref="editDrawer" @success="getList"></BeianEdit>
        <BeianConfirm ref="beianConfirm" @ok="requestSetStatus"></BeianConfirm>
        <BeianDeal ref="beianDeal" @ok="requestSetDeal"></BeianDeal>

    </page-content>
</template>
<script>
    import BeianConfirm from "./BeianConfirm";
    import BeianDeal from "./BeianDeal";
    import BeianEdit from "./BeianEdit";
    import {notification} from "ant-design-vue";
    export default {
        components: {BeianConfirm,BeianDeal,BeianEdit},
        data() {
            return {
                queryParams:{
                    status:null
                },
                productList:[]
            }
        },

        computed: {
            columns() {
                return [
                    {
                        title:  '客户类型',
                        dataIndex: 'customType',
                        customRender: (text)=>{
                            let arr = [ "备案客户","绑定客户" ]
                            return arr[text||0]
                        },
                        filters: [
                            {text:"全部",value:""},
                            {text:"备案客户",value:0},
                            {text:"绑定客户",value:1},
                        ],
                        filterMultiple: false,
                    },
                    {
                        title: '介绍人',
                        dataIndex: 'name',
                    },
                    {
                        title: '介绍人电话',
                        dataIndex: 'phone'
                    },

                    // {
                    //     title: '介绍人地址',
                    //     dataIndex: 'userAddress'
                    // },
                    // {
                    //     title: '介绍人身份',
                    //     dataIndex: 'userType',
                    //     customRender: (text)=>{
                    //         let arr = [ "修理师傅","配件店","贸易公司","机主","其他" ]
                    //         return arr[text]
                    //     },
                    //     filters: [
                    //         {text:"全部",value:""},
                    //         {text:"修理师傅",value:0},
                    //         {text:"配件店",value:1},
                    //         {text:"贸易公司",value:2},
                    //         {text:"机主",value:3},
                    //         {text:"其他",value:4},
                    //     ],
                    //     filterMultiple: false,
                    // },
                    // {
                    //     title: '能否协助',
                    //     dataIndex: 'canHelp',
                    //     scopedSlots: {customRender: 'canHelp'},
                    //     filters: [
                    //         {text:"全部",value:""},
                    //         {text:"可以协助",value:true},
                    //         {text:"无法协助",value:false},
                    //     ],
                    //     filterMultiple: false,
                    // },
                    {
                        title: '介绍人上级',
                        dataIndex: 'parentName',
                        customRender: (text,record) => {
                            if(record.parentIdentityId){
                                return `${record.parentName}(${record.parentPhone})`
                            }else{
                                return "陆志敏"
                            }
                        },
                    },
                    {
                        title: '意向客户',
                        dataIndex: 'targetName',
                    },
                    {
                        title: '意向客户电话',
                        dataIndex: 'targetPhone'
                    },
                    {
                        title: '客户地址',
                        dataIndex: 'targetAddress'
                    },
                    // {
                    //     title: '来访时间',
                    //     dataIndex: 'visitTime'
                    // },
                    {
                        title: '购买意向',
                        dataIndex: 'productName'
                    },
                    // {
                    //     title: '购买价额',
                    //     dataIndex: 'productPrice',
                    //     customRender: (text, row, index) => {
                    //         return text / 100
                    //     }
                    // },
                    {
                        title: '申请时间',
                        dataIndex: 'createdAt'
                    },
                    {
                        title: '审批状态',
                        dataIndex: 'status',
                        scopedSlots: {customRender: 'status'}
                    },
                    {
                        title: '是否成交',
                        dataIndex: 'deal',
                        scopedSlots: {customRender: 'deal'},
                        filters: [
                            {text:"全部",value:""},
                            {text:"已成交",value:true},
                            {text:"未成交",value:false},
                        ],
                        filterMultiple: false,
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        scopedSlots: {customRender: 'operation'},
                        width: 80
                    },
                ]
            }
        },

        created() {
            this.$get('web/beian/product/all').then(suc=>{
                this.productList=suc.data
            })
        },

        methods: {

            handleSetStatus(record, status) {
                //同意，需要确认
                if(status==1){
                    this.$refs.beianConfirm.open(record)
                    return
                }

                this.requestSetStatus({
                    id:record.id,
                    status
                })

            },
            handleSetDeal(record){
                this.$refs.beianDeal.open(record)
            },
            requestSetStatus(data){
                this.$put("web/beian/record/status",data).then(suc=>{
                    this.getList()
                })
            },
            requestSetDeal(data){
                this.$put("web/beian/record/deal", data).then(suc=>{
                    this.getList()
                })
            },
            getList() {
                this.$refs.table.getData()
            },
            clickExport(){
                this.$refs.table.export("备案申请")
            },

            edit(record) {
                this.$refs.editDrawer.show(record,this.productList)
            },
            remove (record) {
                if(record.status==1){
                    notification.warn({
                        message: '操作提示',
                        description: '无法删除已同意的备案',
                        duration: 2
                    })
                    return
                }
                if(record.deal){
                    notification.warn({
                        message: '操作提示',
                        description: '无法删除已成交的备案',
                        duration: 2
                    })
                    return
                }

                let that = this
                this.$confirm({
                    title: '是否删除该备案记录?',
                    content: '该操作不可撤销',
                    centered: true,
                    onOk () {
                        that.$delete('web/beian/record/' + record.id).then(() => {
                            that.$message.success('删除成功')
                            that.getList()
                        })
                    }
                })
            },

        }
    }
</script>
<style lang="less" scoped>

    .news-logo {
        width: 60px;
        height: 60px;
    }
</style>
