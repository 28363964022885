<template>
    <a-modal
            title="确认同意备案申请"
            :visible="visible"
            @ok="clickOk"
            @cancel="close"
    >
        <div class="container">
            <div class="visit-row">
                <div class="user">
                    <div class="head">介绍人</div>
                    <div class="name">{{record.name}}</div>
                    <div class="phone">{{record.phone}}</div>
                    <div class="address">{{record.userAddress}}</div>
                </div>
                <div style="width: 10px"></div>
                <div class="user">
                    <div class="head">意向客户</div>
                    <div class="name">{{record.targetName}}</div>
                    <div class="phone">{{record.targetPhone}}</div>
                    <div class="address">{{record.targetAddress}}</div>
                </div>
            </div>

            <a-descriptions bordered size="small" :column="1">
<!--                <a-descriptions-item label="来访时间">{{record.visitTime}}</a-descriptions-item>-->
                <a-descriptions-item label="能否协助安装">{{record.canHelp?"可以协助":"无法提供协助"}}</a-descriptions-item>
                <a-descriptions-item label="购买意向">{{record.productName}}</a-descriptions-item>
<!--                <a-descriptions-item label="购买价额">{{record.productPrice/100}} 元</a-descriptions-item>-->
                <a-descriptions-item label="产品佣金">{{record.commission/100}} 元</a-descriptions-item>

            </a-descriptions>

        </div>
    </a-modal>
</template>

<script>
    export default {
        props: ["title"],
        data() {
            return {
                visible: false,
                record:{}
            }
        },
        methods: {
            open(record={}) {
                this.record = JSON.parse(JSON.stringify(record))
                this.visible = true
            },
            close() {
                this.visible = false
            },
            clickOk() {
                this.visible = false
                this.$emit("ok",{
                    id:this.record.id,
                    status:1
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        background: #FFF;

        .title {
            text-align: center;
            font-size: 30px;
            border-bottom: 1px solid #DDD;
        }

        .content {

        }

        .row {
            display: flex;
            border-top: 1px solid #DDD;
        }

        .btn-action {
            flex: 1;
            text-align: center;
            font-size: 30px;
        }

        .btn-action:active {
            background: #F0F0F0;
        }

        .btn-cancel {
            border-right: 1px solid #DDD;
            color: #999999;
        }
    }

    .visit-row{
        display: flex;
        margin-bottom: 10px;

        .user{
            flex: 1;
            padding: 10px;
            background: #F0F0F0;
            border-radius: 5px;

            .head{
                color: #0084FF;
                margin-bottom: 5px;
            }

            .address{
                opacity: 0.7;
            }
        }
    }

</style>
