var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-content',[_c('common-table',{ref:"table",attrs:{"path":"web/beian/record","queryParams":_vm.queryParams,"columns":_vm.columns},scopedSlots:_vm._u([{key:"logo",fn:function(ref){
var record = ref.record;
return [_c('a-avatar',{attrs:{"shape":"square","size":60,"src":record.logo}},[_vm._v(_vm._s(record.title))])]}},{key:"status",fn:function(ref){
var record = ref.record;
return [(record.status==0)?_c('a-dropdown',{attrs:{"trigger":['click']}},[_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:"1",on:{"click":function($event){return _vm.handleSetStatus(record,1)}}},[_vm._v("同意")]),_c('a-menu-item',{key:"2",on:{"click":function($event){return _vm.handleSetStatus(record,2)}}},[_vm._v("拒绝")])],1),_c('a-button',{attrs:{"type":"danger","size":"small"}},[_vm._v("待处理 "),_c('a-icon',{attrs:{"type":"down"}})],1)],1):(record.status==1)?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("已同意")]):_c('a-tag',{attrs:{"color":"red"}},[_vm._v("已拒绝")])]}},{key:"deal",fn:function(ref){
var record = ref.record;
return [(record.customType==1)?_c('div',[_vm._v("无需成交")]):(record.deal)?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("已成交")]):_c('a-dropdown',{attrs:{"trigger":['click']}},[_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:"1",on:{"click":function($event){return _vm.handleSetDeal(record)}}},[_vm._v("已成交")])],1),_c('a-button',{attrs:{"type":"danger","size":"small"}},[_vm._v("未成交 "),_c('a-icon',{attrs:{"type":"down"}})],1)],1)]}},{key:"canHelp",fn:function(ref){
var record = ref.record;
return [(record.canHelp)?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("可以协助")]):_c('a-tag',{attrs:{"color":"cyan"}},[_vm._v("无法协助")])]}},{key:"operation",fn:function(ref){
var record = ref.record;
return [_c('action-edit',{on:{"click":function($event){return _vm.edit(record)}}}),_c('action-delete',{on:{"click":function($event){return _vm.remove(record)}}})]}}])},[_c('template',{slot:"search"},[_c('a-radio-group',{on:{"change":function($event){return _vm.$refs.table.handleReset()}},model:{value:(_vm.queryParams.status),callback:function ($$v) {_vm.$set(_vm.queryParams, "status", $$v)},expression:"queryParams.status"}},[_c('a-radio-button',{attrs:{"value":null}},[_vm._v("全部")]),_c('a-radio-button',{attrs:{"value":0}},[_vm._v("待处理")]),_c('a-radio-button',{attrs:{"value":1}},[_vm._v("已同意")]),_c('a-radio-button',{attrs:{"value":2}},[_vm._v("已拒绝")])],1),_c('a-button',{on:{"click":_vm.clickExport}},[_c('a-icon',{attrs:{"type":"export"}}),_vm._v("导出Excel")],1)],1)],2),_c('BeianEdit',{ref:"editDrawer",on:{"success":_vm.getList}}),_c('BeianConfirm',{ref:"beianConfirm",on:{"ok":_vm.requestSetStatus}}),_c('BeianDeal',{ref:"beianDeal",on:{"ok":_vm.requestSetDeal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }